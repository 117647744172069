.side-menu {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5em;

    top: var(--header-height);
    height: calc(100% - var(--header-height));
    overflow-y: auto;
    overflow-x: hidden;

    background-color: var(--menu-background-color);
    box-shadow: var(--default-shadow);


    transition: transform 0.3s ease-out;
    font-size: clamp(0.6rem, 5vw, 1em);
    transform: translateX(-100%);

    .menu {
        li {
            a {                
                color: var(--menu-color);
                display: flex;
                gap: 0.5em;
                padding: clamp(0.3em, 1.3vh, 0.8em) 1.5em;
                font-size: 1.5em;
                transition: all 0.3s;
                &:hover {
                    color: var(--menu-item-hover-color);
                    background-color: var(--menu-item-hover-background-color);
                }
                &.current {
                    font-weight: bold;
                }
                img {
                    max-width: 1.5em;
                    max-height: 1.5em;
                }
            }
            &:first-of-type {
                padding-bottom: 0.2em;
                border-bottom: 1px solid var(--menu-item-hover-background-color);
                a {
                    margin-left: -1em;
                }
            }
            &:last-of-type {
                padding-top: 0.2em;
                border-top: 1px solid var(--menu-item-hover-background-color);
                a {
                    margin-left: -1em;
                }
            }
        }
    }
}

body.menu-open {
    .side-menu {
        transform: translateX(0);
    }

    overflow: hidden;
}

body.menu-open::after {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% - var(--header-height));
    left: 0;
    top: var(--header-height);
    background-color: var(--backdrop-color);
}