.splash-screen {
  padding-top: 2em;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  
  img {
    max-width: min(320px, 90%);
    max-height: min(320px, 90%);
  }
}