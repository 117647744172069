.stats {
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  padding: 2em 0.3em;
  gap: 1em;
  height: 100%;

  .table-wrapper {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    table {
      border-collapse: collapse;
      thead {
        background-color: var(--main-color);
        color: var(--background-color);
        th {
          cursor: pointer;
          text-align: start;
          border: 1px solid var(--background-color);
          &.ascending::after {
            content: ' ▲';
          }
          &.descending::after {
            content: ' ▼';
          }
        }
      }

      tbody {
        overflow: auto;
      }
    }
    table, td, tr {
      border: 1px solid var(--main-color);
    }
  
    td {
      padding: 0.2em 0.5em;
    }
  
    th {
      padding: 0.4em 0.5em;
    }    
  }
}