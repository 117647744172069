footer {
    position: fixed;
    min-height: var(--footer-height);
    min-width: 100%;
    bottom: 0;

    font-size: clamp(0.3rem, 3.5vw, 1em);

    background-color: var(--footer-background-color);
    background-image: url('../assets/images/draw.png');
    background-repeat: no-repeat;
    background-size: clamp(var(--header-height), 30vw, var(--header-height) * 3);
    background-position: 15% 28%;
    background-blend-mode: soft-light;
    box-shadow: var(--default-shadow);


    .controls {
        display: flex;
        align-items: center;
        justify-content: center;        
        width: 100%;
        height: var(--footer-height);
        gap: 3em;
        position: relative;
    }

    .play-controls {
        display: none;
        .scoreboard {
            --score-item-size: 2em;
            --width: calc(var(--score-item-size) * 7);

            position: absolute;
            right: 1em;
            max-width: var(--width);
            height: var(--score-item-size);
            white-space: nowrap;
            overflow: hidden;

            .score-item {
                width: var(--score-item-size);
                height: var(--score-item-size);
                display: inline-block;
                background-image: url('./../assets/images/thumbs-up.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: calc(var(--score-item-size) * 0.7);
            }
            .score-item.down {
                transform: rotate(180deg);
            }
        }

    }

    @media (max-width: 800px) {
        .play-controls {
            justify-content: flex-start;
            padding-left: 1em;
        }
    }

    .stats-controls {
        display: none;
    }

    button {
        padding: 0.5em 1em;
        color: var(--background-color);
        background-color: var(--hightlight-background-color2);
        text-transform: uppercase;
        border-radius: 0.3em;
        font-size: 1.2em;
        box-shadow: var(--switch-shadow);
        
        transition: all 0.3s ease-out;
        &:hover {
            background-color: var(--highlight-color);
        }
    }

    .play-repeat-btn {
        padding-right: 2.5em;
        background-image: url('../assets/images/start.png');
        background-repeat: no-repeat;
        background-position: center right 0.5em;
        background-size: 1.5em;
    }
    .play-repeat-btn.repeat {
        background-image: url('../assets/images/repeat.png');        
    }
}