:root {
    /**** Sizes ****/
    --header-height: 100px;
    --footer-height: 80px;
    --main-height: calc(100% - var(--header-height) - var(--footer-height));

    --card-size: min(280px, 80vw);
    --card-gap: calc(var(--card-size) / 5);
    --max-category-width: calc(var(--card-size) * 4 + var(--card-gap) * 4);

    /**** Shadows ****/
    --default-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    --switch-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    /**** Colors ****/
    --main-color: #023047;
    --background-color: #fff;
    --backdrop-color: rgba(0 0 0 / 0.5);

    --hightlight-background-color1: #8ecae6;
    --hightlight-background-color2: #219ebc;
    --highlight-color: #fb8500;

    --app-title-color: var(--main-color);
    --switch-color: var(--background-color);

    --card-description-color: var(--main-color);
    --card-description-background-color: var(--background-color);

    --menu-background-color: var(--background-color);;
    --menu-item-hover-background-color: var(--main-color);
    --menu-item-hover-color: var(--background-color);
    --menu-color: var(--main-color);

    --header-background-color: #8ecae6aa;
    --footer-background-color: var(--header-background-color);
}