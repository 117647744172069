.category {
  display: flex;
  flex-flow: row wrap;
  max-width: var(--max-category-width);
  margin: 0 auto;
  padding: var(--card-gap) 0;
  justify-content: center;

  .card {
    width: var(--card-size);
    height: var(--card-size);
    margin: calc(var(--card-gap) / 2);
    position: relative;
    perspective: 1000px;
    transition: transform 300ms ease-out;
    font-size: 1.2em;

    &:hover {
      transform: scale((1.06));
    }
    
    .card-inner.flipped {
      transform: rotateY(180deg);
    }

    .card-inner {    
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.5s;
      transform-style: preserve-3d;
      cursor: pointer;          
    
      .card-front, .card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--background-color);        
        backface-visibility: hidden;
        border-radius: 0.5em;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        overflow: hidden;
      }

      .card-front {      
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          object-fit: cover;
          object-position: center center;
        }
        .card-description {
          position: absolute;
          width: 100%;
          height: 25%;
          bottom: 0;
          padding: 0.5em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: var(--card-description-color);
          background-color: var(--card-description-background-color);
          opacity: 0.95;
          mix-blend-mode: hard-light;
          transition: transform 300ms ease-out;
          h3 {
            text-transform: uppercase;
          }
          button {
            background-image: url('./../assets/images/flip.svg');
          }
        }
      }
      
      .card-back {
        transform: rotateY(180deg);
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }

  }
  .card.disabled {
    opacity: 0.5;
    &:hover {
      transform: none;
    }
    .card-inner {
      cursor: auto;
    }
  }
}