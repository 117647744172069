header {
    position: fixed;
    min-height: var(--header-height);
    min-width: 100%;
    font-size: clamp(0.5rem, 3vw, 1em);
    padding: 0 clamp(0.5rem, 3vw, 1.2em);;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--app-title-color);
    background-color: var(--header-background-color);
    background-image: url('../assets/images/read.png');
    background-repeat: no-repeat;
    background-size: clamp(var(--header-height), 30vw, var(--header-height) * 3);
    background-position: 85% center;
    background-blend-mode: soft-light;
    box-shadow: var(--default-shadow);
    z-index: 1;

    h1 {
        text-align: center;
    }

    h3 {
        display: flex;
        gap: 0.5em;
        align-items: center;
        img {
            max-height: 1.2em;
            max-width: 1.2em;
        }
    }

    .menu-toggle-btn {
        font-size: 1em;
        width: 2em;
        height: 2em;
        background-image: url('./../assets/images/menu.svg');
        transition: background-image 300ms ease-in-out;
    }
}

body.menu-open .menu-toggle-btn {
    background-image: url('./../assets/images/close.svg');
}