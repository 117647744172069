.check-box {
  --height: 1.6em;
  --width: 6em;
  --padding: 0.4em;

  position: relative;
  min-width: calc(var(--width) + var(--padding) * 2);
  height: calc(var(--height) + var(--padding) * 2);
  padding: 0.8em;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: var(--background-color);
  background-color: var(--hightlight-background-color2);
  justify-self: end;
  border-radius: 999px;
  box-shadow: var(--switch-shadow);
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: var(--padding);
    left: var(--padding);
    width: var(--height);
    height: var(--height);
    background-color: var(--switch-color);
    border-radius: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -1px inset, rgba(0, 0, 0, 0.3) 0px 2px 4px -1px inset;
    transition: transform 0.3s ease-out;
  }
}

.check-box.checked {
  background-color: var(--highlight-color);
  justify-content: flex-start;

  &::after {
    transform: translateX(calc(var(--width) - var(--height)));
  }  
}


