body.stats-route {
  .stats-controls {
    display: flex;
  }
  .play-controls {
    display: none !important;
  }
}

body.stats-route, body.all-route {
  .play-switcher {
    transform: scale(0); 
  }
}

body.category-route.play-mode {
  .play-controls {
    display: flex;
  }
}