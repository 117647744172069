@use '_variables.scss';
@use '_header.scss';
@use '_menu.scss';
@use '_footer.scss';
@use '_category.scss';
@use '_checkbox.scss';
@use '_play-mode.scss';
@use '_routes.scss';
@use '_splash-screen.scss';
@use '_stats.scss';
@use '_dark-theme.scss';



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

*, *::after, *::before {
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box; 
}

html {
    height: fill-available;
    height: -webkit-fill-available;
}

body {   
    position: relative;
    scroll-behavior: smooth;
    color: var(--main-color);
    background-color: var(--background-color);
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;    

    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

main {
    padding-top: var(--header-height);
    padding-bottom: var(--footer-height);
    height: var(--main-height);
    width: 100%;
}

a, a:visited, a:hover {
    text-decoration: none;
    color: var(--main-color);
}

button {
    outline: none;
    min-width: 1.5em;
    min-height: 1.5em;
    cursor: pointer;
    background-color: transparent;
    border: none;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

ul {
    list-style: none;
}

header, footer {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}