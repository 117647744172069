body.dark-theme {
  --main-color: #fff;
  --background-color: #023047;
  
  --backdrop-color: rgba(0 0 0 / 0.5);
  --hightlight-background-color1: #8ecae6;

  --hightlight-background-color2: #219ebc;

  --highlight-color: #fb8500;

  .category .card .card-inner {        
    .card-front, .card-back {
      box-shadow: rgba(255, 255, 255, 0.25) 0px 6px 12px -2px, rgba(255, 255, 255, 0.3) 0px 3px 7px -3px;
    }
  }
}